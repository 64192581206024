import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { BOOKINGS_CALENDAR_REFERRAL_INFO } from '../../../../constants/constants';
import { getTrackingInfoForCalendarNextButtonClick } from '@wix/bookings-analytics-adapter';
import { bookingsContactInfoSaveSuccess } from '@wix/bi-logger-wixboost-ugc/dist/src/v2';
import { mapFlowTypeToBiProperties } from '../../../../utils/bi/mappers';
import { isBookingsEnabled } from '../../../../utils/serviceUtils/serviceUtils';
import { Service, DynamicPriceInfo } from '@wix/bookings-uou-types';
import { getDynamicPricingInfo } from '../../../../utils/dynamicPricing/dynamicPricing';

export type NavigateToNextPage = ({
  selectedSlots,
  bookingFlow,
}: {
  selectedSlots: SlotAvailability[];
  bookingFlow: {
    isWaitingList: boolean;
    isRescheduling: boolean;
  };
}) => void;

export function createNavigateToNextPageAction(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
): NavigateToNextPage {
  return async ({ selectedSlots, bookingFlow }) => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const { wixSdkAdapter, biLogger, activeFeatures } = context;
    const { availableServices, selectedTime } = state;
    const { isWaitingList, isRescheduling } = bookingFlow;

    const serviceId = selectedSlots[0].slot?.serviceId;
    const selectedService = availableServices.find(
      (service) => service.id === serviceId,
    )!;

    const isTemplateMode = wixSdkAdapter.isTemplateMode();
    const isBookingEnabled = isBookingsEnabled({
      serviceType: selectedService.info.type,
      activeFeatures: activeFeatures!,
      isTemplateMode,
    });

    if (isBookingEnabled) {
      const isBookingFormInstalled =
        await wixSdkAdapter.isBookingFormInstalled();

      void biLogger.report(
        bookingsContactInfoSaveSuccess({
          selectedSlot: selectedTime,
          ...mapFlowTypeToBiProperties({
            isWaitingList,
            isBookingFormInstalled,
          }),
        }),
      );

      if (isRescheduling) {
        handleReschedulingFlow(actionFactoryParams);
      } else if (isWaitingList) {
        handleWaitingListFlow(actionFactoryParams);
      } else {
        const slotAvailability = selectedSlots[0];
        handleRegularBookFlow(
          actionFactoryParams,
          slotAvailability,
          selectedService,
        );
      }
    } else {
      if (wixSdkAdapter.isPreviewMode()) {
        const serviceType = selectedService.info.type;
        await wixSdkAdapter.openPreviewPremiumModal(
          serviceType,
          BOOKINGS_CALENDAR_REFERRAL_INFO,
        );
      }

      if (wixSdkAdapter.isSiteMode()) {
        setState({
          dialog: {
            type: DialogType.PremiumViewer,
            state: DialogState.IDLE,
          },
        });
      }
    }
  };
}

const handleWaitingListFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.JoinWaitlist,
      state: DialogState.IDLE,
    },
  });
};

const handleReschedulingFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.RescheduleConfirm,
      state: DialogState.IDLE,
    },
  });
};

const handleRegularBookFlow = (
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  slotAvailability: SlotAvailability,
  selectedService: Service,
) => {
  const { wixSdkAdapter, businessInfo, experiments } = context;
  const [state] = getControllerState();
  const { selectedTimezone, selectedVariantsOptions } = state;
  const isDynamicPricingUoUEnabled = experiments.enabled(
    'specs.bookings.dynamicPricingUoU',
  );

  const serviceSlug = selectedService!.info.slug;

  const trackingInfo = getTrackingInfoForCalendarNextButtonClick({
    services: [selectedService],
    businessName: businessInfo?.name || '',
  });
  wixSdkAdapter.trackAnalytics(trackingInfo);
  let dynamicPriceInfo: DynamicPriceInfo | undefined;
  if (isDynamicPricingUoUEnabled) {
    dynamicPriceInfo = getDynamicPricingInfo(selectedVariantsOptions);
  }
  void wixSdkAdapter.navigateToBookingsFormPage({
    slotAvailability,
    serviceId: selectedService.id,
    serviceSlug,
    referral: BOOKINGS_CALENDAR_REFERRAL_INFO,
    timezone: selectedTimezone,
    dynamicPriceInfo,
  });
};
