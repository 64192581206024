import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createWeeklyTimeSlotsLayoutViewModel,
  memoizedWeeklyTimeSlotsLayoutViewModel,
} from '../weeklyTimeSlotsLayoutViewModel/weeklyTimeSlotsLayoutViewModel';
import {
  createWeeklyTimetableLayoutViewModel,
  memoizedWeeklyTimetableLayoutViewModel,
} from '../weeklyTimetableLayoutViewModel/weeklyTimetableLayoutViewModel';
import {
  createDailyTimeSlotsLayoutViewModel,
  memoizedDailyTimeSlotsLayoutViewModel,
} from '../dailyTimeSlotsLayoutViewModel/dailyTimeSlotsLayoutViewModel';
import {
  isDailyTimeSlotsWeeklyPickerLayout,
  isDailyAgendaWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';
import { BodyViewModel } from './bodyViewModel.types';
import {
  createDailyAgendaWeeklyPickerLayoutViewModel,
  memoizedDailyAgendaWeeklyPickerLayoutViewModel,
} from '../dailyAgendaWeeklyPickerLayoutViewModel/dailyAgendaWeeklyPickerLayoutViewModel';

export const memoizedBodyViewModelViewModel: MemoizedViewModalFactory<BodyViewModel> =
  {
    dependencies: {
      settings: ['calendarLayout'],
      subDependencies: [
        memoizedDailyTimeSlotsLayoutViewModel.dependencies,
        memoizedWeeklyTimeSlotsLayoutViewModel.dependencies,
        memoizedWeeklyTimetableLayoutViewModel.dependencies,
        memoizedDailyAgendaWeeklyPickerLayoutViewModel.dependencies,
      ],
    },
    createViewModel: createBodyViewModel,
  };

export function createBodyViewModel({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>): BodyViewModel {
  const { settings, settingsParams, experiments } = context;

  if (isWeeklyTimeSlotsLayout(settings, settingsParams)) {
    return {
      weeklyTimeSlotsLayout: createWeeklyTimeSlotsLayoutViewModel({
        state,
        context,
      }),
    };
  }

  if (isWeeklyTimetableLayout(settings, settingsParams)) {
    return {
      weeklyTimetableLayout: createWeeklyTimetableLayoutViewModel({
        state,
        context,
      }),
    };
  }

  if (isDailyAgendaWeeklyPickerLayout(settings, settingsParams)) {
    return {
      dailyAgendaWeeklyPickerLayout:
        createDailyAgendaWeeklyPickerLayoutViewModel({ state, context }),
    };
  }

  const dailyTimeSlotsLayoutViewModel = createDailyTimeSlotsLayoutViewModel({
    state,
    context,
  });
  const isWeeklyLayoutMobileEnabled = experiments.enabled(
    'specs.bookings.weeklyLayoutMobile',
  );

  if (
    isWeeklyLayoutMobileEnabled &&
    isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams)
  ) {
    return {
      dailyTimeSlotsWeeklyPickerLayout: dailyTimeSlotsLayoutViewModel,
    };
  }

  return {
    dailyTimeSlotsMonthlyPickerLayout: dailyTimeSlotsLayoutViewModel,
  };
}
