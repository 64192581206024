import _ from 'lodash';
import type { Experiments } from '@wix/yoshi-flow-editor';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  Service,
  ServiceLocation,
  ServiceLocationType,
  StaffMember,
} from '@wix/bookings-uou-types';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  isCalendarPage,
  isWeeklyTimeTableWidget,
} from '../../../../utils/presets';

export enum FilterTypes {
  SERVICE = 'SERVICE',
  LOCATION = 'LOCATION',
  STAFF_MEMBER = 'STAFF_MEMBER',
}

export type FilterOption = {
  label: string;
  value: string;
  selected: boolean;
  indeterminate?: boolean;
  parentValue?: string | null;
};

export type FilterViewModel = {
  id: FilterTypes;
  label: string;
  options: FilterOption[];
  note?: string;
};

export const memoizedFiltersViewModel: MemoizedViewModalFactory<FilterViewModel> =
  {
    dependencies: {
      state: ['availableServices', 'filterOptions'],
      settings: [
        'serviceLabel',
        'locationLabel',
        'staffMemberLabel',
        'headerLocationFilterVisibility',
        'headerStaffFilterVisibility',
        'headerServiceFilterVisibility',
        'headerFiltersVisibility',
      ],
    },
  };

const isCalendarSortFiltersEnabled = (experiments: Experiments) =>
  experiments.enabled('specs.bookings.CalendarSortFilters');

type CategoryData = { id: string; name: string; services: Service[] };

function getServiceCategories(availableServices: Service[]): CategoryData[] {
  const categories: Record<string, CategoryData> = {};
  availableServices.forEach((service) => {
    const category = categories[service.info.categoryId];
    if (category) {
      category.services.push(service);
    } else {
      categories[service.info.categoryId] = {
        id: service.info.categoryId,
        name: service.info.categoryName,
        services: [service],
      };
    }
  });
  const sortedCategories = Object.values(categories);
  sortedCategories.sort(({ name: firstCategory }, { name: secondCategory }) =>
    firstCategory.localeCompare(secondCategory),
  );
  sortedCategories.forEach(({ services }) =>
    services.sort(({ info: firstService }, { info: secondService }) =>
      firstService.name.localeCompare(secondService.name),
    ),
  );

  return sortedCategories;
}

function getServiceFilterOptions(
  categories: CategoryData[],
  selectedServices: string[],
): FilterOption[] {
  const selectedServicesSet = new Set(selectedServices);
  const serviceToFilterOption =
    ({ categoryId }: { categoryId?: string } = {}) =>
    (service: Service): FilterOption => ({
      value: service.id,
      label: service.info.name,
      selected: selectedServicesSet.has(service.id),
      parentValue: categoryId,
    });

  if (categories.length === 1) {
    return categories[0].services.map(serviceToFilterOption());
  } else {
    return categories
      .map(({ name, id, services }): FilterOption[] => {
        const numberOfSelectedCategoryServices = services.filter((service) =>
          selectedServicesSet.has(service.id),
        ).length;

        const categoryFilterOption = {
          value: id,
          label: name,
          selected: services.length === numberOfSelectedCategoryServices,
          indeterminate:
            numberOfSelectedCategoryServices > 0 &&
            numberOfSelectedCategoryServices < services.length,
          parentValue: null,
        };

        return [
          categoryFilterOption,
          ...services.map(serviceToFilterOption({ categoryId: id })),
        ];
      })
      .flat();
  }
}

function createServiceFilterViewModel(
  state: CalendarState,
  context: CalendarContext,
): FilterViewModel | undefined {
  const {
    getContent,
    reportError,
    experiments,
    settingsParams,
    settings,
    preset,
  } = context;
  const { availableServices, filterOptions } = state;

  const isFilterSeparationEnabled = experiments.enabled(
    'specs.bookings.calendarFiltersSeparation',
  );
  const isServiceFilterVisible = isFilterSeparationEnabled
    ? settings.get(settingsParams.headerServiceFilterVisibility)
    : isWeeklyTimeTableWidget(preset);

  try {
    if (isServiceFilterVisible && availableServices.length > 1) {
      const categories = getServiceCategories(availableServices);
      const options = getServiceFilterOptions(
        categories,
        filterOptions.SERVICE,
      );

      return {
        label: getContent({
          settingsParam: settingsParams.serviceLabel,
          translationKey: 'app.settings.defaults.service-label',
        }),
        options,
        id: FilterTypes.SERVICE,
      };
    }
  } catch (e) {
    reportError(e);
  }
}

function createLocationFilterViewModel(
  state: CalendarState,
  context: CalendarContext,
): FilterViewModel | undefined {
  const {
    getContent,
    reportError,
    experiments,
    settingsParams,
    settings,
    preset,
  } = context;
  const { availableServices, filterOptions } = state;
  const isFilterSeparationEnabled = experiments.enabled(
    'specs.bookings.calendarFiltersSeparation',
  );
  const isLocationFilterVisible = isFilterSeparationEnabled
    ? settings.get(settingsParams.headerLocationFilterVisibility)
    : isCalendarPage(preset) &&
      settings.get(settingsParams.headerFiltersVisibility);

  try {
    if (!isLocationFilterVisible) {
      return;
    }

    const availableLocations = getFilterOptions(
      availableServices,
      'locations',
    ) as ServiceLocation[];
    if (availableLocations.length > 1) {
      const selectedLocationsOptions = filterOptions.LOCATION;
      return {
        label: getContent({
          settingsParam: settingsParams.locationLabel,
          translationKey: 'app.settings.defaults.location-label',
        }),
        options: availableLocations
          .filter(({ type }) => type === ServiceLocationType.OWNER_BUSINESS)
          .sort(
            (
              { businessLocation: firstBusinessLocation },
              { businessLocation: secondBusinessLocation },
            ) =>
              isCalendarSortFiltersEnabled(experiments)
                ? (firstBusinessLocation?.name || '').localeCompare(
                    secondBusinessLocation?.name || '',
                  )
                : 0,
          )
          .map(({ businessLocation }) => ({
            selected: selectedLocationsOptions.some(
              (selectedLocationsOption) =>
                selectedLocationsOption === businessLocation?.id,
            ),
            label: businessLocation?.name || '',
            value: businessLocation?.id || '',
          })),
        id: FilterTypes.LOCATION,
      };
    }
  } catch (e) {
    reportError(e);
  }
}

function createStaffMemberFilterViewModel(
  state: CalendarState,
  context: CalendarContext,
): FilterViewModel | undefined {
  const { getContent, reportError, experiments, settingsParams, settings } =
    context;
  const { availableServices, filterOptions } = state;

  const isFilterSeparationEnabled = experiments.enabled(
    'specs.bookings.calendarFiltersSeparation',
  );
  const isStaffFilterVisible = isFilterSeparationEnabled
    ? settings.get(settingsParams.headerStaffFilterVisibility)
    : settings.get(settingsParams.headerFiltersVisibility);

  try {
    if (!isStaffFilterVisible) {
      return;
    }

    const availableStaffMembers = getFilterOptions(
      availableServices,
      'staffMembers',
    ) as StaffMember[];
    if (availableStaffMembers.length > 1) {
      const selectedStaffMembersOptions = filterOptions.STAFF_MEMBER;
      return {
        label: getContent({
          settingsParam: settingsParams.staffMemberLabel,
          translationKey: 'app.settings.defaults.staff-member-label',
        }),
        options: availableStaffMembers
          .sort(({ name: firstStaffName }, { name: secondStaffName }) =>
            isCalendarSortFiltersEnabled(experiments)
              ? firstStaffName.localeCompare(secondStaffName)
              : 0,
          )
          .map(({ id, name }) => ({
            selected: selectedStaffMembersOptions.some(
              (selectedStaffMembersOption) => selectedStaffMembersOption === id,
            ),
            label: name,
            value: id,
          })),
        id: FilterTypes.STAFF_MEMBER,
      };
    }
  } catch (e) {
    reportError(e);
  }
}

export function createFilterViewModels({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>): FilterViewModel[] {
  const notUndefined = <T>(value: T): value is NonNullable<T> =>
    value !== undefined;

  return [
    createServiceFilterViewModel(state, context),
    createLocationFilterViewModel(state, context),
    createStaffMemberFilterViewModel(state, context),
  ].filter(notUndefined);
}

const getFilterOptions = (
  availableServices: Service[],
  optionType: Extract<keyof Service, 'staffMembers' | 'locations'>,
) => _.uniqWith(_.flatMap(availableServices, optionType), _.isEqual);
