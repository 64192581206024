import {
  ActionFactoryParams,
  SetState,
} from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  LocalDateTimeRange,
  SlotsStatus,
  TriggeredByOptions,
} from '../../../../types/types';
import { AddError } from '../addError/addError';
import { SetSelectedTime } from '../setSelectedTime/setSelectedTime';
import { BottomSectionStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import {
  sendDatePickerLoadedBiEvent,
  sendTimePickerLoadedBiEvent,
} from '../../../../utils/bi/events/events';
import { isDailyTimeSlotsWeeklyPickerLayout } from '../../../../utils/layouts';

export type SetSelectedRange = (
  range: LocalDateTimeRange,
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createSetSelectedRangeAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  addError: AddError,
  setSelectedTime: SetSelectedTime,
): SetSelectedRange {
  return async (range: LocalDateTimeRange, triggeredBy: TriggeredByOptions) => {
    const [state, setState] = getControllerState();
    const { calendarApi, settings, settingsParams, biLogger, experiments } =
      context;
    const { fromAsLocalDateTime, toAsLocalDateTime } = range;
    const selectedRange = {
      from: fromAsLocalDateTime,
      to: toAsLocalDateTime,
    };
    const isDailyTimeSlotWeeklyPickerLayoutEnable = experiments.enabled(
      'specs.bookings.weeklyLayoutMobile',
    );
    if (
      isDailyTimeSlotWeeklyPickerLayoutEnable &&
      isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams)
    ) {
      return setSelectedRangeForDailyWeekly(
        context,
        getControllerState,
        range,
        triggeredBy,
      );
    }
    setState({
      selectedRange,
      selectedDate: selectedRange.from,
      bottomSectionStatus: BottomSectionStatus.LOADED,
      slotsStatus: SlotsStatus.LOADING,
      selectedDateTrigger: triggeredBy,
    });
    setSelectedTime(undefined);

    const availableSlots = await calendarApi.getSlotsInRange(range, {
      state,
      settings,
      settingsParams,
      onError: addError,
    });

    if (fromAsLocalDateTime === getControllerState()[0].selectedRange?.from) {
      setState({ availableSlots });

      if (availableSlots?.availabilityEntries?.length) {
        setState({ slotsStatus: SlotsStatus.AVAILABLE_SLOTS });
      } else {
        setState({
          slotsStatus: SlotsStatus.NO_AVAILABLE_SLOTS,
        });
      }

      sendDatePickerLoadedBiEvent({
        biLogger,
        triggeredBy,
      });

      sendTimePickerLoadedBiEvent({
        availableSlots,
        triggeredBy,
        biLogger,
      });
    }
  };
}

async function setSelectedRangeForDailyWeekly(
  context: CalendarContext,
  getControllerState: () => [
    state: CalendarState,
    setState: SetState<CalendarState>,
  ],
  range: LocalDateTimeRange,
  triggeredBy: TriggeredByOptions,
) {
  const [state, setState] = getControllerState();
  const { calendarApi, settings, settingsParams, biLogger } = context;
  const { fromAsLocalDateTime, toAsLocalDateTime } = range;
  const selectedRange = {
    from: fromAsLocalDateTime,
    to: toAsLocalDateTime,
  };
  setState({
    selectedRange,
  });
  const availableSlotsPerDay = await calendarApi.getDateAvailability(range, {
    state,
    settings,
    settingsParams,
  });
  setState({
    availableSlotsPerDay,
  });

  sendDatePickerLoadedBiEvent({
    biLogger,
    availableSlotsPerDay,
    triggeredBy,
  });
}
